import React, { useState } from 'react';
import { Axios } from "../../../../api/api";
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import {useTranslation} from "react-multi-lang";

const form = {
    margin: 0,
    maxWidth: '100%',
    background: 'none',
    boxShadow: 'none'
}
const MarketStaffCreate = () => {
    const { id } = useParams();
    const t = useTranslation()
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        password: '',
        email: '',
        parent_id: id,
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if(formData && formData.parent_id){
                formData.parent_id = parseInt(formData.parent_id);
            }
            await Axios.post(`/preorder/location/${id}/staff`, formData).then((res) => {
                if(res.status === 200) {
                    toast.success(t('fix.market_created_successfully'))
                    navigate(`/markets/${id}/staff`);
                } else {
                    toast.error('Internal server error')
                }
            });
        } catch (error) {
            console.error("Error creating market:", error);
        }
    };

    // Define hours options (e.g., every hour from 9 AM to 9 PM)

    return (
        <div>
            <h1>{t('fix.create')} {t('fix.users')}</h1>
            <form onSubmit={handleSubmit} style={form}>
                <input
                    type="hidden"
                    name="parent_id"
                    value={id}
                />
                <label>
                    {t('fix.firstname')}:
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.last_name')}:
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.phone_number')}:
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.password')}:
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.email')}:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <button type="submit">{t('fix.create')}</button>
            </form>
        </div>
    );
};

export default MarketStaffCreate;
