import React, { useState, useEffect } from 'react';
import GroupForm from 'src/components/preOrder/GroupForm'; // Adjust import path
import { Axios } from 'src/api/api'; // Adjust import path
import { useParams } from 'react-router-dom';

const EditGroup = () => {
    const { id } = useParams(); // Get group ID from URL params
    const [group, setGroup] = useState(null);
    // const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchGroup = async () => {
            try {
                const response = await Axios.get(`/preorder/group/${id}`);
                setGroup(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching group:', error);
            }
        };

        // const fetchUsers = async () => {
        //     try {
        //         const response = await Axios.get(`/preorder/groups/${id}/users`);
        //         setUsers(response.data);
        //     } catch (error) {
        //         console.error('Error fetching users:', error);
        //     }
        // };

        fetchGroup();
        // fetchUsers();
    }, [id]);

    return (
        <div>
            {group && (
                <GroupForm
                    groupToEdit={group}
                    // users={users}
                />
            )}
        </div>
    );
};

export default EditGroup;
