import React from "react";
import {Routes, Route} from "react-router-dom";
import Dashboard from "../pages/preorder-pages/dashboard/dashboard";
import PendingOrders from "../pages/preorder-pages/orders/pending-orders/index";
import Groups from "../pages/preorder-pages/groups/index";
import Markets from "../pages/preorder-pages/markets/index";
import MarketCreate from "../pages/preorder-pages/markets/create";
import EditGroup from "../pages/preorder-pages/groups/edit";
// import MarketEdit from "../pages/preorder-pages/markets/edit";
// import MarketShow from "../pages/preorder-pages/markets/show";
import History from "../pages/preorder-pages/orders/history/index";
import HeaderPreorder from "../components/header/headerPreorder";
import CreateGroup from "../pages/preorder-pages/groups/create";
import MarketUpdate from "../pages/preorder-pages/markets/edit";
import MarketStaff from "../pages/preorder-pages/markets/staff";
import MarketStaffCreate from "../pages/preorder-pages/markets/staff/create";
import MarketStaffEdit from "../pages/preorder-pages/markets/staff/edit";

export default function PreorderRole() {
    return (
        <>
            <HeaderPreorder/>
            <Routes>
                <Route path='/dashboard' element={<Dashboard/>}/>
                <Route path='/pending-orders' element={<PendingOrders/>}/>
                <Route path='/groups' element={<Groups/>}/>
                <Route path='/history' element={<History/>}/>
                <Route path='/groups/edit/:id' element={<EditGroup/>}/>
                <Route path='/groups/create' element={<CreateGroup/>}/>
                <Route path="/markets" element={<Markets />} />
                <Route path="/markets/create" element={<MarketCreate />} />
                <Route path="/markets/edit/:id" element={<MarketUpdate />} />
                <Route path="/markets/:id/staff" element={<MarketStaff />} />
                <Route path="/markets/:id/staff/create" element={<MarketStaffCreate />} />
                <Route path="/markets/:market_id/staff/:staff_id/edit" element={<MarketStaffEdit />} />
                <Route path='/pre-order/markets' element={<Markets/>}/>
                <Route path='/pre-order/market/create' element={<MarketCreate/>}/>
            </Routes>
        </>
    );
}

