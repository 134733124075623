import React from "react";
import {Routes, Route} from "react-router-dom";
import Dashboard from "../pages/market-pages/dashboard/dashboard";
import PendingOrders from "../pages/market-pages/orders/pending-orders/index";
import History from "../pages/market-pages/orders/history/index";
import HeaderMarket from "../components/header/headerMarket";
//commit
export default function MarketRole() {
    return (
        <>
            <HeaderMarket/>
            <Routes>
                <Route path='/dashboard' element={<Dashboard/>}/>
                <Route path='/pending-orders' element={<PendingOrders/>}/>
                <Route path='/history' element={<History/>}/>
            </Routes>
        </>
    );
}

