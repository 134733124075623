import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import CButton from 'src/cors/button/cButton'; // Adjust import path
import {useTranslation} from "react-multi-lang";
interface Group {
    quantity995: number;
    expired_at995: number;
    quantity999: number;
    expired_at: number;
    discount: number;
    id: number;
    name: string;
}

interface GroupListProps {
    groups: Group[];
    onEdit: (id: number) => void; // Expect id for editing
    onDelete: (id: number) => void; // Expect id for deletion
}

const GroupList: React.FC<GroupListProps> = ({ groups, onEdit, onDelete }) => {
    const t = useTranslation()
    return (
        <div className='tableWrapper pre-order-table' style={{marginTop: '15px'}}>
            <div className="cardItem">
                <h1>{t('fix.groups')}</h1>
                <Link to={`/groups/create`} style={{maxWidth: '150px'}}>
                    <CButton
                        title={t('fix.create_new_group')}
                        borderWidth={1}
                    />
                </Link>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>{t('fix.name')}</th>
                    <th>{t('fix.discount')}</th>
                    <th>{t('fix.duration999')}</th>
                    <th>{t('fix.quantity999')}</th>
                    <th>{t('fix.duration995')}</th>
                    <th>{t('fix.quantity995')}</th>
                    <th>{t('fix.action')}</th>
                </tr>
                </thead>
                <tbody>
                {groups.map(group => (
                    <tr key={group.id}>
                        <td><span>{t('fix.name')}: </span>{group.name}</td>
                        <td><span>{t('fix.discount')} : </span>{group.discount.toFixed(2)}</td>
                        <td><span>{t('fix.duration999')} : </span>{group.expired_at==1?24:48}</td>
                        <td><span>{t('fix.quantity999')} : </span>{group.quantity999}</td>
                        <td><span>{t('fix.duration995')}: </span>{group.expired_at995==1?24:48}</td>
                        <td><span>{t('fix.quantity995')} : </span>{group.quantity995}</td>
                        <td>
                            <Link to={`/groups/edit/${group.id}`}>
                                <CButton
                                    title={t('fix.edit')}
                                    borderWidth={1}
                                />
                            </Link>
                            <br />
                            <CButton
                                onPress={() => onDelete(group.id)}
                                title={t('fix.delete')}
                                borderWidth={1}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default GroupList;
