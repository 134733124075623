import React, { useState, useEffect } from 'react';
import { Axios } from "../../../../api/api";
import { useNavigate, useParams } from 'react-router-dom';
import {toast} from "react-toastify";
import {useTranslation} from "react-multi-lang";

const MarketStaffEdit = () => {
    const t = useTranslation()
    const { market_id, staff_id  } = useParams(); // Get market ID from URL
    console.log(market_id, staff_id);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        password: '',
        email: '',
        parent_id: market_id,
    });
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch existing market data for editing
        const fetchMarket = async () => {
            try {
                const response = await Axios.get(`/preorder/admin/location/${staff_id}`);

                // Set form data
                setFormData({
                    ...response.data
                });
            } catch (error) {
                console.error("Error fetching market data:", error);
            }
        };

        fetchMarket();
    }, [staff_id]);

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            if(market_id){
                formData.parent_id = parseInt(market_id);
            }
            // Convert working_hours and working_days arrays to JSON for database storage
            await Axios.put(`/preorder/location/staff/${staff_id}`, formData).then((res) => {
                if(res.status === 200) {
                    toast.success(t('fix.market_updated_successfully'))
                    navigate(`/markets/${market_id}/staff`);
                } else {
                    toast.error('Internal server error')
                }
            });

        } catch (error) {
            console.error("Error updating market:", error);
        }
    };

    return (
        <div>
            <h1>Edit Market</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="hidden"
                    name="parent_id"
                    value={market_id}
                />
                <label>
                    {t('fix.firstname')}:
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.last_name')}:
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.password')}:
                    <input
                        type="password"
                        name="password"
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.phone_number')}:
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    {t('fix.email')}:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </label>
                <button type="submit">{t('fix.update')}</button>
            </form>
        </div>
    );
};

export default MarketStaffEdit;